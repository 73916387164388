import { createContext, useReducer, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import LoginAPI from '../apis/LoginAPI';
import Cookie from '../lib/cookies';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isVerified: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  login: (username: string, password: string) => Promise<void>;
  verify: (user: string, code: string) => Promise<void>
  logout: () => Promise<void>;
  register: (email: string, user: string, password: string, phone: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User | null;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type VerifyAction = {
  type: 'VERIFY';
  payload: {
    isVerified: boolean;
  };
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User | null;
  };

};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | VerifyAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isVerified: false,
  user: null
};

const setSession = (uuid: string): void => {
  if (uuid != null) {
    Cookie.setCookie(Cookie.Keys.UUID, uuid, 30);
  } else {
    Cookie.deleteCookie(Cookie.Keys.UUID);
  }
};

const handlers: Record<string, (state: State, action: any) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const isAuthenticated = Cookie.hasCookie(Cookie.Keys.UUID);

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: null
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
  }),
  VERIFY: (state: State, action: VerifyAction): State => {
    const { isVerified } = action.payload;
    return {
      ...state,
      isVerified
    };
  },
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const hasToken = Cookie.hasCookie(Cookie.Keys.UUID);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: hasToken
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    await LoginAPI.login(username, password);
    Cookie.setCookie(Cookie.Keys.USERNAME, username, 30);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: null
      }
    });
  };

  const verify = async (username: string, resp: any): Promise<void> => {
    let isVerified = false;
    try {
      setSession(resp.uuid);
      isVerified = true;
    } catch (err) {
      console.error(err);
    }
    dispatch({
      type: 'VERIFY',
      payload: {
        isVerified: isVerified
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    Cookie.deleteCookie(Cookie.Keys.USERNAME);
    dispatch({
      type: 'LOGOUT',
    });
  };

  const register = async (username: string, email: string, password: string, phone_number: string): Promise<void> => {
    const resp = await LoginAPI.createCustomer(username, email, phone_number, password);
    setSession(resp.uuid);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        verify,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
