import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography
} from '@mui/material';

import { getCustomerDataForSelf } from 'src/slices/customerData';
import CustomerDataApi from 'src/apis/CustomerDataApi';
import Cookie from 'src/lib/cookies';
import { CustomerDataResponse } from 'src/apis/types/CustomerDataAPITypes';
import {
  Notifications,
  LibraryAddRounded,
  ChatBubbleRounded,
  PersonRounded,
  CalendarMonthRounded,
  NotificationsRounded,
} from '@mui/icons-material';
import { RootState, useDispatch, useSelector } from '../../store';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const customerDataSelfSelector = (state: RootState): CustomerDataResponse => {
  const { customerData } = state.customerData;
  return customerData.self;
};

const sections = [

  {
    title: '',
    items: [
      /*
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />
      },
      {
        title: 'Finance',
        path: '/dashboard/finance',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      */
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <PersonRounded />
      }
    ]
  },
  {
    title: 'Apps',
    items: [
      {
        title: 'Chat',
        path: '/dashboard/chat/sid',
        icon: <ChatBubbleRounded />
      },
      {
        title: 'Kanban',
        path: '/dashboard/kanban',
        icon: <LibraryAddRounded />
      },
      /*
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />
      },
      */
      {
        title: 'Calendar',
        path: '/dashboard/calendar',
        icon: <CalendarMonthRounded />
      },
      /*
      {
        title: 'Contacts',
        path: '/dashboard/contacts',
        icon: <Users fontSize="small" />
      },
      */
      /*
      {
        title: 'Org Chart',
        path: '/dashboard/org-chart',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      */
      {
        title: 'Notifications',
        path: '/dashboard/notifications',
        icon: <NotificationsRounded />
      }
      // */
    ]
  }
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  let currentCustomerData = useSelector((state) => customerDataSelfSelector(state));
  let customerName = (currentCustomerData == null) ? '' : currentCustomerData.name;
  let customerAvatar = (currentCustomerData == null) ? '' : currentCustomerData.avatarURI;

  const checkForCustomerData = async (): Promise<void> => {
    if (currentCustomerData != null) {
      return;
    }
    dispatch(getCustomerDataForSelf());
    const uuid = Cookie.getCookie(Cookie.Keys.UUID);
    const response = await CustomerDataApi.getCustomerDataByUUID(uuid);
    currentCustomerData = response;
    customerName = currentCustomerData.name;
    customerAvatar = currentCustomerData.avatarURI;
  };

  checkForCustomerData();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={customerAvatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {customerName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
