import { subDays, subHours, subMinutes } from 'date-fns';
import { mock } from '../lib/axios';
import type {
  Contact,
  Thread,
  Message
} from '../types/chat';
import createResourceId from '../utils/createResourceId';

const now = new Date();

const contacts: Contact[] = [
  {
    id: '12346534568712902138',
    avatar: 'https://s3.us-east-2.amazonaws.com/cdn.my-sid/customer_icons/SID.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'SID',
    username: 'sid'
  }
];

const threads: Thread[] = [
  {
    id: '143857-3402587230498572',
    messages: [
      {
        id: '12346534568712902144',
        attachments: [],
        body: 'Hello!',
        contentType: 'text',
        createdAt: now.getTime(),
        senderId: '12346534568712902138'
      },
      {
        id: '12346534568712902145',
        attachments: [],
        body: 'My name is SID. Simple, Intelligent, Device. I am an AI personal assitant.',
        contentType: 'text',
        createdAt: now.getTime(),
        senderId: '12346534568712902138'
      },
      {
        id: '12346534568712902146',
        attachments: [],
        body: 'I can help you with getting the weather and help you query the kanban board',
        contentType: 'text',
        createdAt: now.getTime(),
        senderId: '12346534568712902138'
      },
      {
        id: '12346534568712902147',
        attachments: [],
        body: 'Feel free to ask me thing such as "What\'s the weather on Monday" or "What tasks do I have due next week" or "When is my next meeting?"',
        contentType: 'text',
        createdAt: now.getTime(),
        senderId: '12346534568712902138'
      }
    ],
    participants: [
      {
        id: '12346534568712902138',
        avatar: '',
        name: 'SID',
        username: 'sid'
      }
    ],
    type: 'ONE_TO_ONE',
    unreadCount: 3
  },
];

const findContactByUsername = (username: string): Contact | null => {
  const contact = contacts.find((_contact) => _contact.username === username);

  return contact || null;
};

const findThreadById = (threadId: string): Thread | null => {
  const thread = threads.find((_threadId) => _threadId.id === threadId);

  return thread || null;
};

// This means that we are looking for ONE_TO_ONE thread
const findThreadByOtherParticipantId = (participantId: string): Thread | null => {
  const thread = threads.find((_thread) => {
    if (_thread.type !== 'ONE_TO_ONE') {
      return false;
    }

    const participant = _thread.participants.find((_participant) => (
      _participant.id === participantId
    ));

    return !!participant;
  });

  return thread || null;
};

const findThreadByParticipantIds = (participantIds: string[]): Thread | null => {
  const thread = threads.find((_thread) => {
    if (_thread.participants.length < participantIds.length) {
      return false;
    }

    const foundParticipantIds = new Set();

    thread.participants.forEach((participant) => {
      foundParticipantIds.add(participant.id);
    });

    return foundParticipantIds.size === participantIds.length;
  });

  return thread || null;
};

mock.onGet('/api/chat/contacts').reply(200, { contacts });

mock.onGet('/api/chat/search').reply((config) => {
  try {
    const { query } = config.params;
    let results = contacts;
    if (query) {
      const cleanQuery = query.toLowerCase().trim();
      results = results.filter((contact) => contact.name.toLowerCase().includes(cleanQuery));
    }
    return [200, { results }];
  } catch (err) {
    console.error('[Mock]: ', err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/chat/participants').reply((config) => {
  try {
    const { threadKey } = config.params;
    const participants = [];

    // Thread key might be an ID if thread type is GROUP
    // otherwise it represents an username
    const thread = findThreadById(threadKey);

    if (thread) {
      participants.push(...thread.participants);
    } else {
      const contact = findContactByUsername(threadKey);

      if (contact) {
        participants.push({
          id: contact.id,
          avatar: contact.avatar,
          name: contact.name,
          username: contact.username
        });
      }
    }

    return [200, { participants }];
  } catch (err) {
    console.error('[Mock]: ', err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/chat/threads').reply(200, { threads });

mock.onGet('/api/chat/thread').reply((config) => {
  try {
    const { threadKey } = config.params;

    // Thread key might be an ID if thread type is GROUP
    // otherwise it represents an username
    let thread = findThreadById(threadKey);

    if (thread) {
      return [200, { thread }];
    }

    // At this point, thread key should represent an existing contact
    // If no contact found, the user is trying a shady route
    // If contact exists, user might want to start a new conversation
    const contact = findContactByUsername(threadKey);

    if (!contact) {
      return [404, { message: 'Unable to find the contact' }];
    }

    // This could return a null if no thread found
    thread = findThreadByOtherParticipantId(contact.id);

    return [200, { thread }];
  } catch (err) {
    console.error('[Mock]: ', err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/chat/thread/mark-as-seen').reply((config) => {
  try {
    const { threadId } = config.params;
    const thread = threads.find((_thread) => _thread.id === threadId);

    if (thread) {
      thread.unreadCount = 0;
    }

    return [200, true];
  } catch (err) {
    console.error('[Mock]: ', err);
    return [500, { message: 'Internal server error' }];
  }
});

// Adding a new message to a thread can by done in 3 ways
// 1) By specifying a thread id, this means that the thread already exists
// 2) By specifying the other user id (if ONE_TO_ONE thread), thread might exist
// 3) By specifying a column of recipients, thread might exist
mock.onPost('/api/chat/messages/new').reply((request) => {
  console.log('hello from new message');
  try {
    const { threadId, recipientIds, body } = JSON.parse(request.data);

    // On server get current identity (user) from the request
    const user = {
      id: '5e86809283e28b96d2d38537',
      avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
      name: 'Jane Rotanson',
      username: 'jane.rotanson'
    };

    let thread = null;

    // Try to find the thread by threadId if provided
    if (threadId) {
      thread = findThreadById(threadId);
      if (!thread) {
        return [400, { message: 'Invalid thread id' }];
      }
    }

    // Try to find the thread by recipientIds is provided
    if (recipientIds) {
      const participantIds = [...recipientIds, user.id];

      thread = findThreadByParticipantIds(participantIds);
    }
    const message: Message = {
      id: createResourceId(),
      attachments: [],
      body,
      contentType: 'text',
      createdAt: now.getTime(),
      senderId: user.id
    };
    // If thread exists, add the message
    if (thread) {
      thread.messages.push(message);
    } else {
      // If it does not, it means that we have to create a new thread
      const participants = [user];

      recipientIds.forEach((recipientId) => {
        const contact = contacts.find((_contact) => _contact.id === recipientId);

        if (!contact) {
          throw new Error('Contact not found');
        }

        participants.push({
          id: contact.id,
          avatar: contact.avatar,
          name: contact.name,
          username: contact.username
        });
      });

      thread = {
        id: createResourceId(),
        messages: [message],
        participants,
        type: participants.length === 2 ? 'ONE_TO_ONE' : 'GROUP',
        unreadCount: 0
      };
    }

    const responseData = {
      threadId: thread.id,
      message
    };

    return [200, responseData];
  } catch (err) {
    console.error('[Mock]: ', err);
    return [500, { message: 'Internal server error' }];
  }
});
