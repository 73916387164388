import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField
} from '@mui/material';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useAuth from '../../../hooks/useAuth';
import LoginAPI from '../../../apis/LoginAPI';

const SidLogin: FC = () => {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const errMessage = 'Email or password is incorrect! Please try again.';
  const [loading, setLoading] = useState(false);
  const timer = useRef<number>();
  const { login } = useAuth();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Formik
      initialValues={{ username: undefined, password: '', submit: null }}
      validationSchema={
        Yup
          .object()
          .shape({
            username: Yup
              .string()
              .max(32)
              .required('Username is required'),
            password: Yup
              .string()
              .required('Password is required')
              .max(32)
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
              ),
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          await login(values.username, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }

          if (!auth.isAuthenticated) {
            await LoginAPI.sendTwoFactorSMS(values.username);
            setLoading(true);
            navigate('/authentication/verify-code');
          } else {
            navigate('/dashboard/chat/sid');
          }
        } catch (err) {
          console.error(err);

          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: errMessage });
            setSubmitting(false);
          }
        }
      }}
    >

      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            id="username"
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.username}
            variant="outlined"
            autoComplete="username"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            autoComplete="current-password"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errMessage}
              </FormHelperText>
            </Box>
          )}
          <Box
            sx={{ mt: 2 }}
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
            {loading && (
              <CircularProgress
                size={68}
                style={{ marginTop: '2rem' }}
              />
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SidLogin;
