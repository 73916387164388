import {
  ContactRequestsResponse,
  ContactRequestResponse,
  ContactsReponse,
  ContactResponse,
  UpsertContactRequest,
  UpsertCustomerContactRequestRequest
} from './types/ContactsAPITypes';

const BaseUrl = 'https://5ll18vznrk.execute-api.us-east-2.amazonaws.com/Prod/customer_data_service/v1';

async function getContactRequestsForCustomer(uuid: string): Promise<ContactRequestsResponse> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer/${uuid}contact/request/`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

async function getContactRequest(id: string): Promise<ContactRequestResponse> {
  id = encodeURI(id);
  const resp = await fetch(`${BaseUrl}/contact/request/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

async function getContactsForCustomer(uuid: string): Promise<ContactsReponse> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer/${uuid}/contact/`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

async function getContact(id: string): Promise<ContactResponse> {
  id = encodeURI(id);
  const resp = await fetch(`${BaseUrl}/contact/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertContact(request: UpsertContactRequest) {
  const resp = await fetch(`${BaseUrl}/contact`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertContactRequestRequest(request: UpsertCustomerContactRequestRequest) {
  const resp = await fetch(`${BaseUrl}/contact/request`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  }).then((response) => response.json());
  return resp;
}

const ContactsAPI = {

  getContactRequestsForCustomer,
  getContactRequest,
  getContact,
  getContactsForCustomer,
  upsertContact,
  upsertContactRequestRequest,
};

export default ContactsAPI;
