import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import { SidLogin } from 'src/components/authentication/login';
import Logo from '../../components/Logo';

const Login: FC = () => (
  <div>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ py: '80px' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 150,
                width: 150
              }}
            />
          </RouterLink>
        </Box>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3
              }}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  Log in
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  Log in to SID and get started!
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3
              }}
            >
              <SidLogin />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/authentication/register"
              variant="body2"
            >
              Create new account
            </Link>
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/authentication/password-recovery"
              variant="body2"
            >
              Forgot Password?
            </Link>
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/authentication/username-recovery"
              variant="body2"
            >
              Forgot Username?
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Box>
  </div>
);

export default Login;
