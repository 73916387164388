import { useRef, useState } from 'react';
import type { ElementType, FC } from 'react';
import { useNavigate } from 'react-router';
import { shallowEqual } from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { Notifications, Notification } from 'src/apis/types/NotificationAPITypes';
import { updateNotification } from 'src/slices/notifications';
import Calendar from 'src/icons/Calendar';
import { FolderOpen, NotificationsRounded, Error, Warning } from '@mui/icons-material';
import { useDispatch, useSelector } from '../../store';
import type { RootState } from '../../store';

let unreadCount = 0;

const notificationSelector = (state: RootState): Notification[] => {
  const { notifications } = state.notifications;
  const notifs = notifications.allNotifications;
  unreadCount = 0;
  notifs.forEach((element) => {
    if (!element.seen) {
      unreadCount++;
    }
  });
  return notifs;
};

const iconsMap: Record<string, ElementType> = {
  Kanban: FolderOpen,
  Calendar: Calendar,
  Warning: Warning,
  Error: Error
};

const NotificationsPopover: FC = (props, ref) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const notifications = useSelector((state) => notificationSelector(state), shallowEqual);
  const navigate = useNavigate();

  const handleOpen = (): void => {
    // dispatch(getNotifications());
    setOpen(true);
  };

  const toggleNotificationSeen = (notif: Notification, b?: boolean): void => {
    let val = !notif.seen;
    if (b !== undefined) {
      val = b;
    }
    const updatedObj = { ...notif, ...{ seen: val } };
    dispatch(updateNotification(updatedObj));
  };

  const goToNotification = (notif: Notification): void => {
    toggleNotificationSeen(notif, true);
    navigate(`/dashboard${notif.deepLink}`);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const viewAllNotifications = (): void => {
    navigate('/dashboard/notifications');
  };

  const slicedArray = notifications.slice(0, 5);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={unreadCount}
          >
            <NotificationsRounded fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notifications
          </Typography>
        </Box>
        {
          slicedArray.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  There are no notifications
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {slicedArray.map((notification) => {
                    const Icon = iconsMap[notification.type];
                    return (
                      <ListItem
                        divider
                        key={notification.notificationId}
                        alignItems="flex-start"
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }}
                          >
                            <Icon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemButton
                          onClick={(event) => {
                            goToNotification(notification);
                          }}
                        >
                          <ListItemText
                            primary={(
                              <Link
                                color="textPrimary"
                                sx={{ cursor: 'pointer' }}
                                underline="none"
                                variant="subtitle2"
                              >
                                {notification.title}
                              </Link>
                            )}
                            secondary={notification.description}
                          />
                        </ListItemButton>
                        <ListItemButton
                          onClick={(event) => {
                            toggleNotificationSeen(notification);
                          }}
                          alignItems="center"
                          sx={{
                            height: '100%',
                          }}
                        >
                          <ListItemText
                            sx={{
                              color: (notification.seen) ? 'lightgreen' : 'red',
                              display: 'flex',
                            }}
                          >
                            ⦿
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    onClick={viewAllNotifications}
                  >
                    View All Notifications
                  </Button>
                </Box>
              </>
            )
        }
      </Popover>
    </>
  );
};

export default NotificationsPopover;
