import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import Cookie from 'src/lib/cookies';

import NotificationAPI from 'src/apis/NotificationAPI';

import { Notifications, Notification } from '../apis/types/NotificationAPITypes';

import type { AppThunk } from '../store';
import objFromArray from '../utils/objFromArray';

interface NotificationDataState {
  notifications: {
    allNotifications: Notification[]
    byId: Record<string, Notification>
    allIds: string[]
  }
}

const initialState: NotificationDataState = {
  notifications: {
    allNotifications: [],
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(
      state: NotificationDataState,
      action: PayloadAction<{ notifs: Notifications }>
    ): void {
      const { notifs } = action.payload;
      state.notifications.allIds = [];
      state.notifications.allNotifications = [];
      // state.notifications.allNotifications = notifs.notifications;

      state.notifications.byId = objFromArray(notifs.notifications, 'notificationId');

      notifs.notifications.forEach((element) => {
        state.notifications.allNotifications.push(element);
        state.notifications.allIds.push(element.notificationId);
      });
    },
    updateNotification(
      state: NotificationDataState,
      action: PayloadAction<{ notif: Notification }>
    ): void {
      const { notif } = action.payload;

      const res = state.notifications.allNotifications.map((_n) => {
        if (_n.notificationId === notif.notificationId) {
          return notif;
        }
        return _n;
      });

      Object.assign(state.notifications.allNotifications, res);
      Object.assign(state.notifications.byId[notif.notificationId], notif);
    },
  }
});

export const { reducer } = slice;

export const getNotifications = (): AppThunk => async (dispatch): Promise<void> => {
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const notifs = await NotificationAPI.getNotifications(uuid);
  await dispatch(slice.actions.getNotifications({ notifs }));
};

export const updateNotification = (notif: Notification): AppThunk => async (dispatch): Promise<void> => {
  const uuid = { customerUUID: Cookie.getCookie(Cookie.Keys.UUID) };
  const seen = { seen: notif.seen };
  const data = { ...notif, ...uuid, ...seen };
  const resp = await NotificationAPI.updateNotification(data);
  await dispatch(slice.actions.updateNotification({ notif }));
};

export default slice;
