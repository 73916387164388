import { AIMessageRequest, AIMessageResponse } from './types/CoreAIAPITypes';

const BaseUrl = 'https://y7jb0glyih.execute-api.us-east-2.amazonaws.com/Prod';

async function sendMessage(message: AIMessageRequest): Promise<AIMessageResponse> {
  const body = message;
  const resp = await fetch(`${BaseUrl}/message`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

const CoreAIAPI = {
  sendMessage,
};

export default CoreAIAPI;
