import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import Cookie from 'src/lib/cookies';

import ContactsAPI from 'src/apis/ContactsApi';
import { ContactRequestResponse, ContactResponse, UpsertCustomerContactRequestRequest } from 'src/apis/types/ContactsAPITypes';

import type { AppThunk } from '../store';
import objFromArray from '../utils/objFromArray';

interface ContactState {
  contacts: {
    byId: Record<string, ContactResponse>;
    allIds: string[];
    allContacts: ContactResponse[];
  };
  contactRequests: {
    byId: Record<string, ContactRequestResponse>
    allIds: string[];
    allRequests: ContactRequestResponse[];
  };
}

const initialState: ContactState = {
  contacts: {
    byId: {},
    allIds: [],
    allContacts: [],
  },
  contactRequests: {
    byId: {},
    allIds: [],
    allRequests: [],
  }
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts(
      state: ContactState,
      action: PayloadAction<{ contacts: ContactResponse[] }>
    ): void {
      const { contacts } = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
      state.contacts.allContacts = Object.values(state.contacts.byId);

      const arr = [];
      arr.push(contacts);
    },
    getContactRequests(
      state: ContactState,
      action: PayloadAction<{ contactRequests: ContactRequestResponse[] }>
    ): void {
      const { contactRequests } = action.payload;

      state.contactRequests.byId = objFromArray(contactRequests);
      state.contactRequests.allIds = Object.keys(state.contactRequests.byId);
      state.contactRequests.allRequests = Object.values(state.contactRequests.byId);

      const arr = [];
      arr.push(contactRequests);
    },
    updateContactRequest(
      state: ContactState,
      action: PayloadAction<{ contactRequest: ContactRequestResponse }>
    ): void {
      const { contactRequest } = action.payload;

      Object.assign(state.contactRequests.byId[contactRequest.contactRequestId], contactRequest);
    }
  }
});

export const { reducer } = slice;

export const getContacts = (): AppThunk => async (dispatch): Promise<void> => {
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const response = await ContactsAPI.getContactsForCustomer(uuid);

  dispatch(slice.actions.getContacts(response));
};

export const getContactRequests = (): AppThunk => async (dispatch): Promise<void> => {
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const response = await ContactsAPI.getContactRequestsForCustomer(uuid);

  dispatch(slice.actions.getContactRequests(response));
};

export const upsertContactRequest = (
  request: UpsertCustomerContactRequestRequest
): AppThunk => async (dispatch): Promise<void> => {
  const response = await ContactsAPI.upsertContactRequestRequest(request);

  dispatch(slice.actions.updateContactRequest(response));
};

export default slice;
